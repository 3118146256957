import React, { startTransition, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../../utils/helper";
import { http } from "../../../utils/http";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import AddHomeIcon from '@mui/icons-material/AddHome';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import ListIcon from '@mui/icons-material/List';
import logo from "../../../assets/images/logo.svg";
import { GlobalStyles, Avatar, Divider, IconButton, Drawer, List, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import { appUrl, avatarPath, userPath } from "../../../utils/config";

// Navigation items configuration
const NAVIGATION = [
  { segment: "propertyList", title: "Property list", icon: <ListIcon sx={{ fontSize: 'large' }} /> },
  { segment: "property", title: "Add property", icon: <AddHomeIcon sx={{ fontSize: 'large' }} /> },
  {
    segment: "chats", // Define segment for Inbox (Chats)
    title: "Inbox",
    icon: <MailOutlineOutlinedIcon sx={{ fontSize: 'large' }} />,
  },
  {
    segment: "application", // Define segment for Inbox (Chats)
    title: "Applications",
    icon: <DescriptionIcon sx={{ fontSize: 'large' }} />,
  },
];
const MainLayout = () => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [session, setSession] = useState({
    user: {
      name: 'User',
      email: 'user@gmail.com',
      image: '',
    },
  });

  // Check if the user is logged in and fetch user details
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    } else {
      getUserDetails();
    }
  }, []);

  const handleNavigation = (path) => {
    setDrawerOpen(false);
    setTimeout(() => navigate(path), 200);
  };
  // Fetch user details from the server
  const getUserDetails = async () => {
    try {
      const res = await http.get("/profile");
      if (res?.status === 200) {
        startTransition(() => {
          setSession((prev) => (
            {
              ...prev, user: {
                ...prev.user,
                image: appUrl + '/' + avatarPath + '/' + userPath + '/' + res?.data?.avatar,
                name: `${res?.data?.fname} ${res?.data?.lname}`,
                email: res?.data?.email,
              }
            }));
        });
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  return (
    <>
      <GlobalStyles styles={{ '.MuiBox-root.css-23htwk': { overflow: 'unset' } }} />
      <AppProvider session={session} navigation={NAVIGATION} branding={{ logo: <img src={logo} alt="Hexon logo" />, title: "" }}>
        <DashboardLayout
          slots={{
            toolbarAccount: () => (
              <IconButton onClick={() => setDrawerOpen(true)}>
                <Avatar src={session?.user?.image} />
              </IconButton>
            )
          }}
          sx={{ "& .MuiListItemText-primary": { fontSize: "18px" }, backgroundColor: "#f4f4f4", zIndex: 1000 }}
        >
          <Outlet />
        </DashboardLayout>
      </AppProvider>

      {/* Drawer component for displaying user profile and options */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div style={{ width: 250, padding: 16 }}>
          <Avatar src={session?.user?.image} sx={{ width: 56, height: 56, marginBottom: 2 }} />
          <div>{session?.user?.name}</div>
          <div style={{ fontSize: 'small', color: 'gray' }}>{session?.user?.email}</div>
          <Divider sx={{ my: 2 }} />
          <List>
            <ListItemButton onClick={() => { handleNavigation('/profile'); }}>
              <ListItemIcon><PersonIcon /></ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => {
              setSession(null);
              localStorage.removeItem("token");
              localStorage.removeItem("userDetail");
              navigate("/login");
              setDrawerOpen(false);
            }}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default MainLayout;