import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Box, Typography, ThemeProvider, createTheme } from '@mui/material';
import logoIcon from "../../../assets/images/logoicon.svg";
import SignupForm from './SignupForm';
import SignupOtp from './SignupOtp';

// const Signup = () => {
//   const [state, setState] = useState(true)
//   return (
//     <>
//       <div className='common_login_screens signupPage'>
//         <Row className=''>
//           <Col xs={12} lg={4} className='d-none d-lg-flex'>
//             <div className='signUpBox w-100'>
//               <Link className='signUpLogo d-none d-lg-block' to="/dashboard"><LogoIcon /></Link>
//               <div className='signUpContent'>
//                 <img src="LogoIcon" />
//                 <h2>Hexon</h2>
//                 {/* <ul>
//                   <li><CheckcircleIcon /> Perfectly manage your properties</li>
//                   <li><CheckcircleIcon /> Project Features</li>
//                   <li><CheckcircleIcon /> Project Features</li>
//                   <li><CheckcircleIcon /> Project Features</li>
//                 </ul> */}
//               </div>
//             </div>
//           </Col>
//           <Col xs={12} lg={8} className='d-flex'>
//             <div className='login_Col w-100'>
//               <div className='donotAccount'>
//                 <Link className='signUpLogo d-block d-lg-none' to="/dashboard"><LogoIcon /></Link>
//                 <p>Already have an account? <Link to="/login">Sign In</Link></p>
//               </div>
//               <div className='login_box'>
//                 <div className='login_box_heading'>
//                   <Link className='d-none d-lg-block' to="/dashboard">
//                     <LogoIcon />
//                   </Link>
//                   <h2>Sign up</h2>
//                 </div>
//                 <div className={`${state?'d-block':'d-none'}`}>
//                   <SignupForm setState={setState}/>
//                 </div>
//                 <div className={`${state?'d-none':'d-block'}`}>
//                   <SignupOtp setState={setState}/>
//                 </div>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     </>
//   );
// };

// export default Signup;

const Signup = () => {
  const [state, setState] = useState(true);

  // Create theme (reuse from login page)
  const THEME = createTheme({
    typography: {
      htmlFontSize: 18,
      fontSize: 16,
    },
  });

  return (
    <ThemeProvider theme={THEME}>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            padding: 5, // Padding inside the box
            width: '100%',
            maxWidth: 600,
            backgroundColor: '#ffffff',
            boxShadow: 3,
            borderRadius: 2,
          }}
        >
          {/* Already have an account? Section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 3,
              alignItems: 'center',
            }}
          >
            <Link className="signUpLogo" to="/dashboard">
              <img
                src={logoIcon}
                alt="Logo"
                style={{
                  height: '40px',
                }}
              />
            </Link>
            <Typography
              variant="body2"
              sx={{ fontSize: "1.25rem" }}
            >
              Already have an account?{' '}
              <Link to="/login">Sign In</Link>
            </Typography>
          </Box>

          {/* Sign-Up Box */}
          <Box>
            <Typography
              component="h1"
              variant="h3"
              align="center"
              sx={{ mb: 4, fontSize: '3.5rem', fontWeight: 'bold' }}
            >
              Sign up
            </Typography>

            <Box sx={{ display: state ? 'block' : 'none' }}>
              <SignupForm setState={setState} />
            </Box>
            <Box sx={{ display: state ? 'none' : 'block' }}>
              <SignupOtp setState={setState} />
            </Box>
            <Typography
              variant="body1"
              align="center"
              sx={{ mt: 4, fontSize: '1.2rem' }}
            >
              This site is protected by reCAPTCHA and the Google Privacy Policy.
            </Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Signup;
