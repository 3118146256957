//base url dev mode
export const baseUrl = process.env.REACT_APP_API_URL;
export const appUrl = 'http://assets.hexon.ai';
export const uploadPhoto_url = 'http://api.hexon.ai';
export const assets = 'assets';
export const propertyPath = 'property';
export const avatarPath = 'avatar';
export const userPath = 'user';
export const tenantPath = 'tenant'
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const openAiApiKey = process.env.REACT_APP_OPENAI_API_KEY;