// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_not_found__HvWy3 {
  text-align: center;
  margin: auto 0;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/NotFound/NotFound.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,cAAA;AAEF","sourcesContent":["@import '../../../assets/theme/_var.scss';\n.not_found {\n  text-align: center;\n  margin: auto 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not_found": `NotFound_not_found__HvWy3`
};
export default ___CSS_LOADER_EXPORT___;
