import React, { Suspense } from "react";
import { lazy } from "react";
import MainLayout from "../components/layouts/MainLayout/MainLayout";
import NotFound from "../components/pages/NotFound/NotFound";
import Signup from "../components/pages/Signup/Signup";
import { isLoggedIn } from "../utils/helper";
import { Navigate } from "react-router-dom";
import Loader from "../components/common/Loader/Loader";

const Login = lazy(() => import("../components/pages/Login/Login"));
const PropertyDetails = lazy(() =>
  import("../components/pages/PropertyDetails/PropertyDetails")
);
const PropertyList = lazy(() => import("../components/pages/PropertyList/PropertyList"));
const AddProperty = lazy(() => import("../components/pages/Property/Property"));
const Chats = lazy(() => import("../components/pages/Chats/Chats"));
const Profile = lazy(() => import("../components/pages/profile/Profile"));
const Application = lazy(() => import("../components/pages/Document/PropertyListPage"));
const ApplicationView = lazy(() => import("../components/pages/Document/ApplicationsPage"));
const LoadingPage = lazy(() => import("../components/pages/LoadingPage/Loadingpage"));
const NotRecord = lazy(() => import("../components/pages/NoRecord/NotRecord"));

export const routes = [
  {
    path: '/',
    element: <LoadingPage />,
    index: true,
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<Loader generate="Loading Login Page..." />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/signup',
    element: (
      <Suspense fallback={<Loader generate="Loading Signup Page..." />}>
        <Signup />
      </Suspense>
    ),
  },
  {
    path: '/',
    element: isLoggedIn() ? (
      <Suspense fallback={<Loader generate="Loading Main Layout..." />}>
        <MainLayout />
      </Suspense>
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      {
        index: true, // 默认子路由
        element: <PropertyList />,
      },
      {
        path: 'propertyList',
        element: (
          <Suspense fallback={<Loader generate="Loading Property List..." />}>
            <PropertyList />
          </Suspense>
        ),
      },
      {
        path: 'property/:id?',
        element: <AddProperty />,
      },
      {
        path: 'property-details/:id',
        element: <PropertyDetails />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'chats',
        element: <Chats />,
      },
      {
        path: 'application',
        element: <Application />,
      },
      {
        path: 'properties/:propertyId/applications',
        element: (
          <Suspense fallback={<Loader generate="Loading Applications..." />}>
            <ApplicationView />
          </Suspense>
        ),
      },
      {
        path: 'no-record',
        element: <NotRecord />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];