// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Errormsg_error_msg__G1QUn {
  font-size: 1.4rem;
  color: #FF6A55;
  padding-top: 0.4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/common/formik/ErrorMsg/Errormsg.module.scss","webpack://./src/assets/theme/_var.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,cCyBQ;EDxBR,mBAAA;AACF","sourcesContent":["@import '../../../../assets/theme/_var.scss';\n\n.error_msg {\n  font-size: 1.4rem;\n  color: $redclr1;\n  padding-top: 0.4rem;\n}\n","@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');\n\n// ***** fonts *****\n$basefont: 'Roboto', sans-serif;\n$basefont2: 'Acme', sans-serif;\n\n// **** colors ****\n$white: white;\n$white1: #f1f0ff;\n$whitebg: #FCFCFC;\n$whitebg1: #f2f4f7;\n$black: black;\n$blackclr1: #272B30;\n$blackclr2: rgba(0, 0, 0, 0.25);\n$blackbg: #1a1b2b;\n$greytextclr: #9A9FA5;\n$greytextclr1: #6F767E;\n$greytextclr2: #979797;\n$blueclr: #2A85FF;\n$bluebg: #2A85FF;\n$textclr: #a9a9a9;\n$textclr1: #6b7280;\n$borderclr: #f8f9fc;\n$borderclr1: #EBEDF4;\n// $inputbg: #efffff;\n$inputbgfocus: #eeffff;\n$baseclr: #1d1d1d;\n$redclr: #C92020;\n$redclr1: #FF6A55;\n$lightredbg: rgba(255, 188, 153, 0.25);\n$lightwhiteclr: rgba(255, 255, 255, 0.48);\n$lightwhiteclr1: rgba(255, 255, 255, 0.2);\n$inputbg: #F4F4F4;\n$purpleclr: #ad00ff;\n$graylight: #2a2b2f;\n$greybg: #EFEFEF;\n$greybg1: #D0D0D0;\n$gradientclr: linear-gradient(180deg, #171828 0%, #232434 100%);\n\n// **** z indexes *****\n$loaderIndex: 1057;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error_msg": `Errormsg_error_msg__G1QUn`
};
export default ___CSS_LOADER_EXPORT___;
