import React, { createContext, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { routes } from "./routes/routes";
import RouterComponent from "./routes/RouterComponent";

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState({
    imageUrl: null,
  });

  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  const updateUserProfile = (newUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...newUserData,
    }));
  };

  return (
    <UserContext.Provider value={{ user, updateUserProfile }}>
      {/* Load Google Maps API Script */}
      {/* <Script
        url={`https://maps.googleapis.com/maps/api/js?key=AIzaSyD3c5qTV3Hac4BrYF0ILmDCcwIfYiGiaXc&libraries=places&language=en`}
        onLoad={handleScriptLoad}
        onError={handleScriptError}
      /> */}

      {/* Render application only when Google Maps API is loaded */}
      {googleMapsLoaded ? (
        <BrowserRouter>
          <RouterComponent routes={routes} />
        </BrowserRouter>
      ) : (
        <div>Loading Google Maps API...</div>
      )}

      {/* Toaster Notifications */}
      <Toaster
        toastOptions={{
          className: "custom-toast",
          position: "top-center",
          reverseOrder: false,
        }}
      />
    </UserContext.Provider>
  );
}

export default App;
